import React from "react";
import classnames from "classnames";

import styles from "./styles.module.less";
import heroes from "./heroes.module.less";

const Hero = ({
    children,
    background = "defaultHero",
    customPhoto = "",
    photoAlignment = "center",
    compact = false,
}) => {
    let heroBackground = !!background ? background : "defaultHero";
    let cls = classnames(styles.container, {
        [heroes[heroBackground]]: !customPhoto,
        [styles.compact]: compact,
    });
    let style = {
        backgroundPosition: photoAlignment || "center",
    };
    if (!!customPhoto) {
        style.backgroundImage = `url("${customPhoto}")`;
    }
    return (
        <section className={styles.Hero}>
            <div className={cls} style={style} />
            {children}
        </section>
    );
};

export default Hero;
