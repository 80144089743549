import React, { PureComponent } from "react";

import Hero from "../Hero";
import HeroQuote from "../HeroQuote";

import ModuleList from "../ModuleList";
import withSurvey from "../withSurvey";

import styles from "./styles.module.less";

class LoanOfficerDetail extends PureComponent {
    render() {
        const {
            firestoreId,
            slug,
            applyNow = "",
            applyNowScript = "",
            logIn = "",
            title,
            jobTitle,
            hero = {},
            name,
            email,
            phone,
            mobilePhone,
            fax,
            modules = [],
            branch = {},
            team = {},
            blogs = [],
            corporateBlogs = [],
            licenseIds = [],
            facebook,
            twitter,
            linkedin,
            youtube,
            instagram,
            gallery = [],
            isPreview,
            owner,
            stars,
            ratingsCount,
            testimonials: review = [],
            isSurveyInit: isReviewInit,
            hasSurveyErrored: hasReviewErrored,
            shouldDisplaySurvey,
            profile,
            searchVisibility,
            licenseLink,
        } = this.props;

        const { photo = "", image1 = "", image2 = "", image3 = "" } =
            gallery || {};
        let imageList = [photo, image1, image2, image3];
        const {
            quote = "",
            byline = "",
            heroBackground = "",
            customPhoto = "",
            photoAlignment = "center",
        } = hero;

        let address1 = "";
        let address2 = "";
        let address3 = "";
        let city = "";
        let state = "";
        let zip = "";

        if (branch) {
            address1 = branch.address1 || "";
            address2 = branch.address2 || "";
            address3 = branch.address3 || "";
            city = branch.city || "";
            state = branch.state || "";
            zip = branch.zip || "";
        }
        const contactInfo = {
            address1,
            address2,
            address3,
            city,
            state,
            zip,
            phone,
            mobilePhone,
            fax,
            email,
        };

        const moduleListProps = {
            firestoreId,
            modules,
            applyNow,
            applyNowScript,
            twitter,
            contactInfo,
            isPreview,
            blogs,
            corporateBlogs,
            ownerImg: photo,
            title,
            review,
            isReviewInit,
            hasReviewErrored,
            blogWord: "my",
            templateKey: "loan-officer",
            owner,
            logIn,
            slug,
            socialIcons: [],
            licenseIds,
            name,
            jobTitle,
            email,
            phone,
            mobilePhone,
            fax,
            team,
            stars,
            ratingsCount,
            isSurveyInit: isReviewInit,
            hasSurveyErrored: hasReviewErrored,
            shouldDisplaySurvey,
            gallery,
            branch,
            profile,
            pageTitle: title,
            pageUrl: "",
            pageType: "loan-officer",
            searchVisibility,
            licenseLink,
        };

        const socialIconsProps = {
            facebook,
            twitter,
            linkedin,
            youtube,
            instagram,
        };
        moduleListProps.socialIcons = socialIconsProps;

        let branchLoanOfficers = !!branch.loanOfficers
            ? branch.loanOfficers
                  .filter(lo => !!lo && !lo.hidden)
                  .map(lo => {
                      const emptyLo = {
                          slug: "",
                          photo: "",
                          name: "",
                          email: "",
                          jobTitle: "",
                          licenseIds: "",
                      };
                      if (lo) {
                          try {
                              const {
                                  slug = "",
                                  gallery,
                                  profile: {
                                      name = "",
                                      email = "",
                                      jobTitle = "",
                                      licenseIds = [],
                                  },
                              } = lo;

                              const photo =
                                  gallery && gallery.photo ? gallery.photo : "";

                              return {
                                  slug,
                                  photo,
                                  name,
                                  email,
                                  jobTitle,
                                  licenseIds,
                              };
                          } catch (e) {
                              console.log(
                                  "LoanOfficerDetail branch.loanOfficers map error for branch " +
                                      branch.title +
                                      ":  " +
                                      e,
                              );
                              return emptyLo;
                          }
                      } else {
                          console.log(
                              "LoanOfficerDetail branch.loanOfficers has null item for branch " +
                                  branch.title +
                                  ":  " +
                                  e,
                          );
                          return;
                      }
                  })
            : [];

        let branchTeams = !!branch.teams
            ? branch.teams
                  .filter(t => !!t && !t.hidden)
                  .map(team => {
                      const emptyTeam = {
                          slug: "",
                          photo: "",
                          profile: {
                              name: "",
                              email: "",
                              jobTitle: "",
                              licenseIds: [],
                          },
                      };

                      try {
                          const { slug = "" } = team;

                          const licenseIds =
                              profile && profile.licenseIds
                                  ? profile.licenseIds
                                  : [];

                          return {
                              slug,
                              photo: "",
                              name: team.title,
                              email: team.profile.email,
                              jobTitle: "",
                              licenseIds: team.profile.licenseIds,
                          };
                      } catch (e) {
                          console.log("branch.teams map error:  " + e);
                          return emptyTeam;
                      }
                  })
            : [];

        let officers = branchLoanOfficers.concat(branchTeams);
        moduleListProps.loanOfficers = officers;
        moduleListProps.managers = branch.managers;
        moduleListProps.staff = branch.staff;

        return (
            <>
                <Hero
                    background={heroBackground}
                    customPhoto={customPhoto}
                    photoAlignment={photoAlignment}>
                    <HeroQuote quote={quote} subhead={byline} />
                </Hero>
                <ModuleList {...moduleListProps} />
            </>
        );
    }
}

export default withSurvey(LoanOfficerDetail);
