import React from "react";

import styles from "./styles.module.less";

const HeroQuote = ({ quote = "", subhead = "", light = false }) => {
    if (!quote) {
        return null;
    }

    let trimmedQuote = quote;
    if (quote.length > 140) {
        trimmedQuote = quote.substring(0, 140) + "…";
    }

    return (
        <div className={styles.HeroQuote}>
            <div className={styles.container}>
                <hr className={styles.divider} />
                <h1 className={styles.blockquote}>{trimmedQuote}</h1>
                <SubHead subhead={subhead} />
            </div>
        </div>
    );
};

export default HeroQuote;

const SubHead = ({ subhead }) => {
    if (subhead) {
        return (
            <div className={styles.subheadWrapper}>
                <hr className={styles.divider} />
                <p className={styles.subhead}>{subhead}</p>
                <hr className={styles.divider} />
            </div>
        );
    }
    return (
        <div className={styles.subheadWrapper}>
            <hr className={styles.divider} />
        </div>
    );
};
